import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const Seguridad = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Seguridad Web</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Proporcionamos servicios de seguridad informática para su Sitio
              web, solucionamos problemas de seguridad
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/seguridadweb.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              La seguridad web es ahora más que nunca una necesidad para las
              empresas que utilizan la web como medio de difusión de sus
              productos o servicios.
              <br /> <br />
              Cada día miles de sitios web son víctimas de ataques mal
              intensionados con diferentes propósitos como extraer información,
              apropiamiento de servidores, presentación de información ofensiva,
              uso de servidores para hosteo de malware, entre otros objetivos.
              <br /> <br />
              En Yaku realizamos un proceso completo primero de validación de
              seguridad, para luego, generar soluciones que permitan a su sitio
              web o aplicación web contar con todas las defensas necesarias para
              poder contrarestar en forma efectiva ataques y hackeos. <br />
              <br />
              Es un proceso continuo el que se realiza con soporte 24/7 ante
              detección de intromisiones, incremento de errores internos, uso
              excesivo de ancho de banda, bloqueo de IPs, zonas de ips y de ser
              necesario incluso bloqueo de países completos. <br /> <br />
              Utiizamos diferentes métodos de acuerdo a la situación de cada
              sitio web. En algunos casos será necesario implementar soluciones
              de protección, en otros casos será necesario incluso el formateo
              de hosting, uso de nuevas tecnologías. <br /> <br />
              En casos extremos contamos también con partners en los Estados
              Unidos que permiten realizar un proceso de optimización de ips,
              sitios espejo, entre otros.
              <br /> <br />
              <br />
              <Boldie>Experiencia</Boldie>
              <br /> <br />
              Hemos creado soluciones para empresas líderes del sector privado.
              También trabajamos con proyectos del sector público. Con mucho
              gusto le podemos hacer llegar nuestro portafolio de proyectos
              creados. Contáctenos pronto!
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Seguridad
